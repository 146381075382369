import React from 'react';
import './App.css';
import ReactCrop, {Crop} from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {AppBar, Box, Button, Container, Toolbar, Typography} from "@mui/material";

interface State {
  photo?: Photo,
  crop: Crop,
  inputRef: React.RefObject<HTMLInputElement>,
}

interface Photo {
  source: string,
  size?: {
    width: number,
    height: number,
  },
}

class App extends React.Component<{}, State> {
  constructor(props: State) {
    super(props);
    this.state = {
      inputRef: React.createRef(),
      crop: {
        unit: '%',
        aspect: 1875.0 / 1275.0,
        x: 0,
        y: 0,
        width: 100,
        height: 0,
      }
    }
  }

  render() {
    return <div className="App">
      <AppBar position="relative">
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            Mailmento
          </Typography>
        </Toolbar>
      </AppBar>

      <main>
        <Container sx={{p: 4}}>
          {this.state.photo && <Box>
            <ReactCrop
              src={this.state.photo.source}
              crop={this.state.crop}
              onChange={(crop, percentageCrop) => {
                const size = this.state.photo?.size;
                if (size && percentageCrop.width / 100.0 * size.width >= 1875 &&
                  percentageCrop.height / 100.0 * size.height >= 1275) {
                  this.setState(state => ({
                    ...state,
                    crop: crop
                  }))
                }
              }}
              onImageLoaded={image => {
                if (image.naturalWidth >= 1875 && image.naturalHeight >= 1275) {
                  this.setState(state => ({
                    ...state,
                    photo: {
                      source: image.src,
                      size: {
                        width: image.naturalWidth,
                        height: image.naturalHeight,
                      },
                    },
                    crop: {
                      unit: '%',
                      aspect: 1875.0 / 1275.0,
                      x: 0,
                      y: 0,
                      width: 100,
                      height: 0,
                    },
                  }))
                } else {
                  this.setState(state => ({
                    ...state,
                    photo: undefined
                  }))
                }
                return false;
              }}
              keepSelection={true}
            />
          </Box>
          }
          <Button
            size="large"
            variant="contained"
            onClick={() => {
              this.state.inputRef.current?.click()
            }}>
            {this.state.photo ? 'Change Photo' : 'Add Photo'}
          </Button>
          <input
            style={{display: "none"}}
            ref={this.state.inputRef}
            onChange={event => {
              const file = event.target.files?.[0]
              if (file) {
                const photoUrl = URL.createObjectURL(file)
                this.setState(state => ({
                  ...state,
                  photo: {
                    source: photoUrl,
                  },
                }))
              }
            }}
            accept="image/*"
            type="file"
          />
          <Button
            size="large"
            variant="contained"
            onClick={async (event) => {
              const data = new FormData();
              data.append('name', 'Image Upload');

              if (this.state.photo !== undefined) {
                let file = await fetch(this.state.photo.source).then(r => r.blob());
                data.append('file', file);
              }

              await fetch(
                process.env["REACT_APP_MAILMENTO_BACKEND"]!,
                {
                  method: 'post',
                  body: data,
                }
              );
            }}>
            Submit
          </Button>
        </Container>
      </main>
    </div>;
  }
}

export default App;
